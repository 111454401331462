.i-large,
.i-medium,
.i-small {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* z-index: 1; */
}

.i-medium {
    background: url("./asset/mediumSnow.png") repeat 0px 0px;
    -webkit-animation: dropFlowParticles 12s linear infinite;
    -o-animation: dropFlowParticles 12s linear infinite;
    animation: dropFlowParticles 12s linear infinite;
}

.i-small {
    background: url("./asset/smallSnow.png") repeat 0px 0px;
    -webkit-animation: dropFlowParticles 27s linear infinite;
    -o-animation: dropFlowParticles 27s linear infinite;
    animation: dropFlowParticles 27s linear infinite;
}

@-webkit-keyframes dropFlowParticles {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 0 1000px;
    }
}

@keyframes dropFlowParticles {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 0 1000px;
    }
}