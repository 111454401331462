.home {
  position: relative;
  /* background: linear-gradient(180deg, hsl(210, 88%, 20%), hsl(210, 70%, 58%)); */
  background: #1f4335;
  /* height: 650px; */
  padding-top: 7rem;
  overflow: hidden;
}

.home__title {
  /* color: #fff; */
  /* font-size: 2rem; */
  text-align: center;
  margin-top: 1.5rem;
}

/* .home img {
  position: absolute;
} */

.home__mountain-1,
.home__snow {
  bottom: 0;
}

.home__village,
.home__pine,
.home__moon,
.home__trineo {
  left: 0;
  right: 0;
  margin: 0 auto;
}

.home__village {
  width: 260px;
  bottom: 1rem;
}

.home__pine {
  width: 250px;
  bottom: 4rem;
}

.home__mountain-2 {
  bottom: 3rem;
}

.home__mountain-3 {
  bottom: 6rem;
}

.home__moon {
  /* width: 300px; */
  /* bottom: 8.5rem; */
}

.home__trineo {
  width: 250px;
  /* bottom: 14.5rem; */
}

@media screen and (min-width: 576px) {
  .home__village {
    width: 360px;
  }

  .home__pine {
    width: 330px;
  }
}

@media screen and (min-width: 767px) {
  /* .home {
    height: 800px;
  } */

  .home__village {
    width: 460px;
  }

  .home__pine {
    width: 400px;
    bottom: 7rem;
  }

  /* .home__moon {
    width: 450px;
    bottom: 12rem;
  } */

  .home__trineo {
    width: 350px;
    bottom: 20rem;
  }
}

@media screen and (min-width: 1024px) {
  /* .home__title {
    font-size: 4rem;
  } */
}

@media screen and (min-width: 1200px) {
  /* .home {
    height: 980px;
  } */

  /* .home__title {
    font-size: 2.5rem;
  } */

  .home__mountain-1,
  .home__mountain-2,
  .home__mountain-3,
  .home__snow {
    width: 100%;
  }

  .home__village {
    width: 750px;
    bottom: 4rem;
  }

  .home__pine {
    width: 720px;
    bottom: 12.5rem;
  }

  .home__mounain-1,
  .home__mounain-2 {
    bottom: -.5rem;
  }

  .home__moon {
    /* width: 600px; */
    bottom: 14rem;
  }

  .home__trineo {
    width: 500px;
    bottom: 28rem;
  }
}

@media screen and (min-width: 1920px) {
  .home {
    /* max-width: 1280px; */
    /* height: 990px; */
    margin-inline: auto;
  }
}