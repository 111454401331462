.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-content {
    display: flex;
    flex-direction: column;
    border: 0;
    border-radius: 10px;
    /* justify-content: center; */
    width: 400px;
    height: 500px;
    /* opacity: 0.9; */
    background-color: #2C2C2C;
    /* overflow: auto;
    margin: 0 auto; */
    /* align-items: center; */
    /* justify-content: center; */
}

.loader {
    border: 8px solid #f3f3f3;
    /* Light grey */
    border-top: 8px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (min-width: 1440px) {
    .popup-content {
        width: 600px;
        height: 500px;
    }
}